<template>
    <AddEditForm :headerTitle="$t('pages.shares.shareholderDetailInfo')" :onGoBack="onGoBack" :showDelete="false">
        <template v-slot:headerAddEdit>
            <button type="button" name="add-shareholder" class="btn btn-primary" @click="onSave">
                {{ $t('common.update') }}
            </button>          
        </template>
        <template #content>
            <Accordion :headerTitle="$t('common.generalInfo')" v-if="shareholderDetail != null">
                <template v-slot:body>
                    <CForm>
                        <CInput :placeholder="$t('pages.shares.enterNameOfPersonOrOrganization')"
                                v-model="shareholderDetail.personOrOrganization" required horizontal :is-valid="validator">                           
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('pages.shares.nameOfPersonOrOrganization') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>
                        <div role="group" class="form-group form-row">
                            <div class="col-form-label col-sm-3">
                                <label>{{$t('pages.agent.ownerName')}} </label><small class="requiredInput"> (*)</small>
                            </div>
                            <div class="col-sm-9">
                                <multiselect :options="userList"
                                             v-model="searchInfo.selectedOwner"
                                             :loading="isLoadingUsers"
                                             :searchable="true"
                                             :close-on-select="true"
                                             :clear-on-select="false"
                                             :multiple="false"
                                             :placeholder="$t('common.typetosearch')"
                                             :noOptionsText="$t('common.theListIsEmpty')"
                                             :reset-after="false"
                                             :local-search="false"
                                             @search-change="asyncFind"
                                             id="ajax"
                                             label="name"
                                             track-by="id">
                                </multiselect>
                            </div>
                        </div>
                        <CInput :placeholder="$t('common.enterPhoneNumber')" v-model="shareholderDetail.phone" @keypress="$func.validPhoneInput" horizontal required>
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('common.phone') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>

                        <CInput :placeholder="$t('common.enterEmail')" v-model="shareholderDetail.email" :is-valid="$func.validatorEmail" required horizontal type="email">                           
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('common.email') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>

                        <CurrencyInput v-model="shareholderDetail.amountInvested" :label="$t('pages.shares.amountInvested')">
                            <template v-slot:headerRight>
                                <small class="requiredInput">(*)</small>
                            </template>
                        </CurrencyInput>

                        <CInput :placeholder="$t('pages.shares.amountInvested')" v-model="shareholderDetail.numOfShares " @keypress="$func.onlyNumbers" horizontal>
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('pages.shares.numberOfShares') }}
                                    <small class="requiredInput">(*)</small>
                                </div>
                            </template>
                        </CInput>

                        <CInput :label="$t('common.address')" :placeholder="$t('common.address')"
                                v-model="shareholderDetail.address" horizontal>
                        </CInput>

                    </CForm>
                </template>
            </Accordion>
            <Confirmation ref="confirmation"></Confirmation>
        </template>
    </AddEditForm>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import Confirmation from '@/components/Confirmation.vue'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'
    import CurrencyInput from '@/components/CurrencyInput.vue'
    
    export default {
        name: 'shareholderDetail',
		data() {            
			return {                
                horizontal: { label: 'col-3', input: 'col-9' },               
                isLoadingUsers: false,
                userList: [],
            };
		},
        components: {
            Accordion,
            Confirmation,
            AddEditForm,
            CurrencyInput
        },
        computed: {            
            ...mapState('shareholder', ['shareholderDetail', 'searchInfo'])
        },
        methods: {
            ...mapActions('shareholder', ['getShareholderById']),
            validator(val) {
                return val ? val.length > 0 : false
            },
           
            onGoBack() {
                this.$router.push('/shareholders/list');
            },
            onSave() {
                this.onUpdateShareholder();
            },
            async onUpdateShareholder() {
                var retVal = await this.$store.dispatch("shareholder/saveShareholder");                
                if (retVal) {                                      
                    this.onGoBack();
                }        
            },
            async asyncFind(query, id) {
                /* console.log("LENGTH", query.length);*/
                if (query.length >= 3) {
                    this.isLoadingUsers = true;
                    var result = await this.$accountService.searchByName(query);
                    this.isLoadingUsers = false;
                    this.userList = result;
                }
                //console.log("Search", { query, id });
            },
        },
        mounted() {
            this.getShareholderById(this.$route.params.id);            
        },
	}
</script>

<style scoped>
    .btn-primary {
        background-color: #3c8dbc;
        border-color: #367fa9;
    }

        .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
            color: #fff;
            background-color: #0062cc;
            border-color: #005cbf;
        }

    .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d;
    }

    a:hover {
        color: #0056b3;
        text-decoration: none;
    }

    a {
        color: #0056b3;
    }

    .content-header h1 {
        font-size: 1.8rem;
        margin: 0;
    }

    .content-header > h1 {
        margin-bottom: 10px;
    }

    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }

    .content-header > h1 > small {
        color: #0076bb;
        font-weight: 400;
        margin-left: 6px;
        font-size: 65%;
    }

        .content-header > h1 > small i {
            font-size: .8em;
            padding-right: 2px;
        }

    header {
        background: #3c4b64
    }

    h5 {
        font-size: 1.09375rem;
    }

        h5 a {
            color: #fff;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    .float-right button {
        margin-left: 0.2rem;
    }
</style>